<template>
  <div class="tool-view">
    <div style="flex: 1;"></div>
    <a-button type="primary" @click="touchAdd()"><plus-outlined />添加任务</a-button>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :loading="isLoading"
    :pagination="false"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record, index }">
      <!-- 是否开启 -->
      <template v-if="column.key === 'status'">
        <a-switch
          checked-children="开"
          un-checked-children="关"
          :disabled="isLoading"
          :checked="!!record.status"
          @change="changeSwitch(record.id, $event)"
        />
      </template>
      <!-- 设置 -->
      <template v-if="column.key === 'is_daily'">
        {{ record[column.key] === 1 ? '每日重复' : '一次性' }}
      </template>
      <!-- 操作 -->
      <template v-if="column.key === 'operation'">
        <!-- 设置 -->
        <a-button
          type="link"
          @click="handleEdit(record)"
        >
          设置
        </a-button>
        <!-- 上移：第一组禁用 -->
        <a-button
          type="link"
          :disabled="index === 0"
          @click="handleSort(record, 'up')"
        >
          上移
        </a-button>
        <!-- 下移：最后一组禁用 -->
        <a-button
          type="link"
          :disabled="index === dataSource.length - 1"
          @click="handleSort(record, 'down')"
        >
          下移
        </a-button>
      </template>
    </template>
  </a-table>
  <!-- 添加/编辑 -->
  <Add ref="RefAdd" @success="getFrontSourceList"></Add>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { Platforms, FrontSource } from '@/utils/constantList'
import { message } from 'ant-design-vue'
import Add from './Add'
import { frontSourceList, frontSourceSort, frontOtherTaskSwitch } from '@/api/operate'

// Add组件实例
let RefAdd = ref(null)
// props
const props = defineProps({
  platform: Number
})
// source类型
const source = FrontSource.other_task
// 加载
let isLoading = ref(false)
// 数据源
let dataSource = ref([])
// 列数据源
let columns = ref([
  {
    title: '排序',
    dataIndex: 'sort',
    key: 'sort'
  },
  {
    title: '前台名称',
    dataIndex: 'show_name',
    key: 'show_name'
  },
  {
    title: '副标题',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: '任务类型',
    dataIndex: 'is_daily',
    key: 'is_daily'
  },
  {
    title: '是否开启',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 钩子函数
onBeforeMount(() => {
  // 获取配置列表
  getFrontSourceList()
})

// 获取配置列表
function getFrontSourceList () {
  isLoading.value = true
  frontSourceList({
    source,
    platform_id: props.platform
  }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      dataSource.value = data
    } else {
      message.error(res.message || msg)
    }
  })
}

// 是否开启
function changeSwitch (id, boolean) {
  const params = {
    id,
    status: boolean ? 1 : 0
  }
  frontOtherTaskSwitch(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 更新列表
      getFrontSourceList()
    } else {
      message.error(res.message || msg)
    }
  })
}

// 排序板块
function handleSort (record, type) {
  isLoading.value = true
  const params = {
    source,
    id: record.id,
    type
  }
  frontSourceSort(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 更新列表
      getFrontSourceList()
    } else {
      message.error(res.message || msg)
    }
  })
}

// 添加
function touchAdd () {
  RefAdd.value.showDrawer({
    // 当前平台
    platform: props.platform,
      // 当前平台
    id: undefined,
  })
}

// 编辑
function handleEdit (record) {
  RefAdd.value.showDrawer({
    // id
    id: record.id,
    // name
    name: record.name,
    // 当前平台
    platform: props.platform,
  })
}

</script>

<style lang="less" scoped>
.tool-view {
  display: flex;
  align-items: center;
  padding: 10px 10px 20px;
}
.table-view {
  margin-top: 10px;
  :deep(.ant-table-cell) {
    vertical-align: top;
  }
  .play-name {
    &:not(:last-child) {
      margin-bottom: 28px;
    }
    .gap {
      margin: 0 8px;
    }
  }
}
a[disabled] {
  pointer-events: none;
  cursor: default;
}
.tag-type {
  margin-left: 20px;
  background: #eee;
}
</style>